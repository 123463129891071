.content-preview ul, 
.content-preview ol {
  list-style-position: outside !important;
  margin-left: 20px !important;
  padding-left: 20px !important;
}

.content-preview ul li {
  list-style-type: disc !important;
  display: list-item !important;
  margin-bottom: 8px !important;
}

.content-preview ol li {
  list-style-type: decimal !important;
  display: list-item !important;
  margin-bottom: 8px !important;
}

.content-preview table {
  border-collapse: collapse !important;
  width: 100% !important;
  margin-bottom: 1rem !important;
  border: 1px solid #ddd !important;
}

.content-preview th,
.content-preview td {
  border: 1px solid #ddd !important;
  padding: 8px !important;
  text-align: left !important;
}

.content-preview th {
  background-color: #f5f5f5 !important;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.content-preview {
  /* Base text size */
  font-size: 1rem; /* 16px */
}

.content-preview h1 {
  font-size: 2rem !important; /* 32px */
  margin-bottom: 1rem !important;
}

.content-preview h2 {
  font-size: 1.5rem !important; /* 24px */
  margin-bottom: 0.875rem !important;
}

.content-preview h3 {
  font-size: 1.25rem !important; /* 20px */
  margin-bottom: 0.75rem !important;
}

.content-preview p {
  font-size: 1rem !important; /* 16px */
  margin-bottom: 1rem !important;
}

.content-preview li {
  font-size: 1rem !important; /* 16px */
}

/* Responsive text sizes */
@media (min-width: 768px) {
  .content-preview {
    font-size: 1.125rem; /* 18px */
  }
  
  .content-preview h1 {
    font-size: 2.25rem !important; /* 36px */
  }
  
  .content-preview h2 {
    font-size: 1.75rem !important; /* 28px */
  }
  
  .content-preview h3 {
    font-size: 1.5rem !important; /* 24px */
  }
  
  .content-preview p,
  .content-preview li {
    font-size: 1.125rem !important; /* 18px */
  }
}

@media (min-width: 1024px) {
  .content-preview {
    font-size: 1rem; /* 20px */
  }
  
  .content-preview h1 {
    font-size: 2.5rem !important; /* 40px */
  }
  
  .content-preview h2 {
    font-size: 2rem !important; /* 32px */
  }
  
  .content-preview h3 {
    font-size: 1.75rem !important; /* 28px */
  }
  
  .content-preview p,
  .content-preview li {
    font-size: 1rem !important; /* 20px */
  }
}

/* Add these rules to your existing CSS */
.content-preview img {
  max-width: 100% !important;
  height: auto !important;
  margin: 1.5rem 0 !important;
  border-radius: 0.375rem !important; /* Optional: adds slight rounding to images */
}

/* Medium screens */
@media (min-width: 768px) {
  .content-preview img {
    max-width: 85% !important;
    display: block !important;
  }
}

/* Large screens */
@media (min-width: 1024px) {
  .content-preview img {
    max-width: 75% !important;
  }
}

/* Optional: Handle very wide images differently */
.content-preview img.wide-image {
  max-width: 100% !important;
  width: 100% !important;
}

/* Optional: Handle small images */
.content-preview img.small-image {
  max-width: 300px !important;
  margin-left: 0 !important;
}

/* Add to your existing CSS */
.content-preview {
  width: 100% !important;
  overflow-x: auto !important;
  padding: 0 0.5rem !important;
}

/* Mobile-first table styles */
.content-preview table {
  display: block !important;
  overflow-x: auto !important;
  white-space: nowrap !important;
  -webkit-overflow-scrolling: touch !important;
}

@media (max-width: 640px) {
  .content-preview ul,
  .content-preview ol {
    margin-left: 10px !important;
    padding-left: 10px !important;
  }
  
  .content-preview h1 {
    font-size: 1.5rem !important;
    margin-bottom: 0.75rem !important;
  }
  
  .content-preview h2 {
    font-size: 1.25rem !important;
    margin-bottom: 0.625rem !important;
  }
  
  .content-preview h3 {
    font-size: 1.125rem !important;
    margin-bottom: 0.5rem !important;
  }
  
  .content-preview p,
  .content-preview li {
    font-size: 0.9375rem !important;
    line-height: 1.6 !important;
  }
  
  .content-preview td,
  .content-preview th {
    padding: 6px !important;
    font-size: 0.875rem !important;
  }
}

/* Improved mobile image handling */
@media (max-width: 640px) {
  .content-preview img {
    width: 100% !important;
    margin: 1rem 0 !important;
  }
  
  .content-preview .small-image {
    max-width: 100% !important;
    width: auto !important;
    margin: 1rem auto !important;
    display: block !important;
  }
}

/* Code blocks and pre elements */
.content-preview pre {
  overflow-x: auto !important;
  padding: 1rem !important;
  margin: 1rem 0 !important;
  border-radius: 0.375rem !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.content-preview code {
  font-size: 0.875rem !important;
  padding: 0.2rem 0.4rem !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  border-radius: 0.25rem !important;
}

/* 2560px (2XL) and above */
@media (min-width: 2560px) {
  .content-preview {
    font-size: 3rem !important; /* 24px */
    line-height: 1.8 !important;
  }
  
  .content-preview h1 {
    font-size: 4rem !important; /* 56px */
    margin-bottom: 2rem !important;
    line-height: 1.2 !important;
  }
  
  .content-preview h2 {
    font-size: 3.5rem !important; /* 48px */
    margin-bottom: 1.75rem !important;
    line-height: 1.2 !important;
  }
  
  .content-preview h3 {
    font-size: 3rem !important; /* 40px */
    margin-bottom: 1.5rem !important;
    line-height: 1.2 !important;
  }
  
  .content-preview p {
    font-size: 2rem !important; /* 24px */
    margin-bottom: 1rem !important;
    line-height: 1.8 !important;
  }

  .content-preview span {
    font-size: 2rem !important; /* 24px */
    line-height: 1.8 !important;
  }
  
  .content-preview li {
    font-size: 2rem !important; /* 24px */
    margin-bottom: 1rem !important;
    line-height: 1.8 !important;
  }
  
  .content-preview ul,
  .content-preview ol {
    margin-left: 2.5rem !important;
    padding-left: 2.5rem !important;
    margin-bottom: 2rem !important;
  }
  
  .content-preview img {
    /* max-width: 100% !important; */
    width: 100% !important;
    border-radius: 0.75rem !important;
  }
  
  .content-preview table {
    margin-bottom: 2rem !important;
  }
  
  .content-preview th,
  .content-preview td {
    padding: 1.25rem !important;
    font-size: 1.5rem !important;
  }
  
  .content-preview pre {
    padding: 2rem !important;
    margin: 2rem 0 !important;
    border-radius: 0.75rem !important;
    font-size: 2.375rem !important;
  }
  
  .content-preview code {
    font-size: 2.375rem !important;
    padding: 0.3rem 0.6rem !important;
  }
  
  .content-preview blockquote {
    padding: 2rem !important;
    margin: 2rem 0 !important;
    font-size: 2.5rem !important;
    border-left-width: 6px !important;
  }
}

/* Handle wide images specifically for 2XL screens */
@media (min-width: 2560px) {
  .content-preview img.wide-image {
    max-width: 100% !important;
    margin: 3rem 0 !important;
  }
  
  .content-preview img.small-image {
    max-width: 500px !important;
  }
}

.content-preview {
  width: 100% !important;
  overflow-x: visible !important; /* Changed from auto to visible */
  padding: 0 0.5rem !important;
}

/* Responsive table styles */
.content-preview table {
  display: table !important; /* Changed from block to table */
  width: 100% !important;
  overflow-x: visible !important; /* Changed from auto to visible */
  white-space: normal !important; /* Changed from nowrap to normal */
  margin-bottom: 1rem !important;
  border: 1px solid #ddd !important;
}

.content-preview td,
.content-preview th {
  border: 1px solid #ddd !important;
  padding: 8px !important;
  text-align: left !important;
  word-wrap: break-word !important;
  max-width: 300px !important; /* Add max-width to force text wrapping */
}

/* Mobile table styles */
@media (max-width: 640px) {
  .content-preview table {
    font-size: 0.875rem !important;
  }
  
  .content-preview td,
  .content-preview th {
    padding: 6px !important;
    font-size: 0.875rem !important;
    max-width: 200px !important; /* Smaller max-width for mobile */
  }
}

/* Tablet and desktop table styles */
@media (min-width: 768px) {
  .content-preview table {
    font-size: 1rem !important;
  }
  
  .content-preview td,
  .content-preview th {
    padding: 8px !important;
    font-size: 1rem !important;
  }
}

/* 2XL screens table styles */
@media (min-width: 2560px) {
  .content-preview table {
    font-size: 2rem !important;
  }
  
  .content-preview td,
  .content-preview th {
    padding: 1.25rem !important;
    font-size: 2rem !important;
    max-width: 600px !important; /* Larger max-width for 2XL screens */
  }
}

/* Content text wrapping styles */
.content-preview p,
.content-preview li,
.content-preview h1,
.content-preview h2,
.content-preview h3,
.content-preview h4,
.content-preview h5,
.content-preview h6 {
  white-space: normal !important;
  word-wrap: break-word !important;
  overflow-wrap: break-word !important;
}

/* Responsive table styles */
.content-preview table {
  display: table !important;
  width: 100% !important;
  overflow-x: visible !important;
  white-space: normal !important;
  margin-bottom: 1rem !important;
  border: 1px solid #ddd !important;
  border-collapse: collapse !important;
}

.content-preview td,
.content-preview th {
  border: 1px solid #ddd !important;
  padding: 8px !important;
  text-align: center !important; /* Changed from left to center */
  vertical-align: middle !important; /* Added to vertically center content */
  word-wrap: break-word !important;
  max-width: 300px !important;
}

.content-preview th {
  background-color: #f5f5f5 !important;
  text-align: center !important; /* Ensure headers are also centered */
}



/* Tablet and desktop table styles */

@media (max-width: 640px) {
  .content-preview table {
    font-size: 0.875rem !important;
  }
  
  .content-preview td,
  .content-preview th {
    padding: 6px !important;
    font-size: 0.875rem !important;
    max-width: 200px !important;
  }
}

@media (min-width: 2560px) {
  .content-preview table {
    font-size: 2rem !important;
  }
  
  .content-preview td,
  .content-preview th {
    padding: 1.25rem !important;
    font-size: 2rem !important;
    max-width: 600px !important;
  }
}

@media (min-width: 768px) {
  .content-preview table {
    font-size: 1rem !important;
  }
  
  .content-preview td,
  .content-preview th {
    padding: 8px !important;
    font-size: 1rem !important;
  }
}

